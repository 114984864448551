import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Push Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Pendlay Row 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`15 S2OH (115/75)`}</p>
    <p>{`40 Double Unders`}</p>
    <p>{`12 S2OH (135/95)`}</p>
    <p>{`30 Double Unders`}</p>
    <p>{`9 S2OH (155/105)`}</p>
    <p>{`20 Double Unders`}</p>
    <p>{`6 S2OH (175/115)`}</p>
    <p>{`10 Double Unders`}</p>
    <p>{`3 S2OH (195/125)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 5/4/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, June 2nd is our monthly free workout at 9:00 &
10:00am so bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, June 3rd we will have a Mobility for Athletic Performance
workshop from 1:45-3:00pm.  This workshop will be taught by Stephanie
Bellissimo and will focus on floor work and locomotion for CrossFit
athletes.  This intro class is free to all CrossFit the Ville members
and just \\$15 for non members.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      